import { push } from 'connected-react-router'
import React, { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { useParams } from 'react-router-dom'
import NavigationPrompt from 'react-router-navigation-prompt'

import accountCustomText from '@react-page/accountCustomText'
import '@react-page/accountCustomText/lib/index.css'
import actions from '@react-page/actions'
import '@react-page/actions/lib/index.css'
import anualCost from '@react-page/anualCost'
import '@react-page/anualCost/lib/index.css'
import anualCostParticipation from '@react-page/anualCostParticipation'
import '@react-page/anualCostParticipation/lib/index.css'
import appliancesAdvice from '@react-page/appliancesAdvice'
import '@react-page/appliancesAdvice/lib/index.css'
import community from '@react-page/community'
import '@react-page/community/lib/index.css'
import dailyConsume from '@react-page/dailyConsume'
import '@react-page/dailyConsume/lib/index.css'
import dailyConsume_v2 from '@react-page/dailyConsume_v2'
import '@react-page/dailyConsume_v2/lib/index.css'
import Editor, { Value } from '@react-page/editor'
import '@react-page/editor/lib/index.css'
import energyCosts from '@react-page/energyCosts'
import '@react-page/energyCosts/lib/index.css'
import energyDistributionPeriods from '@react-page/energyDistributionPeriods'
import '@react-page/energyDistributionPeriods/lib/index.css'
import energyDistributionPeriodsDetail from '@react-page/energyDistributionPeriodsDetail'
import '@react-page/energyDistributionPeriodsDetail/lib/index.css'
import energyDistributionPeriods_v2 from '@react-page/energyDistributionPeriods_v2'
import '@react-page/energyDistributionPeriods_v2/lib/index.css'
import energyPenalties from '@react-page/energyPenalties'
import '@react-page/energyPenalties/lib/index.css'
import energyYearEvolution from '@react-page/energyYearEvolution'
import '@react-page/energyYearEvolution/lib/index.css'
import energyYearEvolution_v2 from '@react-page/energyYearEvolution_v2'
import '@react-page/energyYearEvolution_v2/lib/index.css'
import highlightActions from '@react-page/highlightActions'
import '@react-page/highlightActions/lib/index.css'
import image from '@react-page/image'
import '@react-page/image/lib/index.css'
import invoiceBreakdown from '@react-page/invoiceBreakdown'
import '@react-page/invoiceBreakdown/lib/index.css'
import invoiceBreakdownDetail from '@react-page/invoiceBreakdownDetail'
import '@react-page/invoiceBreakdownDetail/lib/index.css'
import invoiceBreakdown_v2 from '@react-page/invoiceBreakdown_v2'
import '@react-page/invoiceBreakdown_v2/lib/index.css'
import permanentConsume from '@react-page/permanentConsume'
import '@react-page/permanentConsume/lib/index.css'
import powerCosts from '@react-page/powerCosts'
import '@react-page/powerCosts/lib/index.css'
import powerMaximeters from '@react-page/powerMaximeters'
import '@react-page/powerMaximeters/lib/index.css'
import powerOptimization from '@react-page/powerOptimization'
import '@react-page/powerOptimization/lib/index.css'
import powerPenalties from '@react-page/powerPenalties'
import '@react-page/powerPenalties/lib/index.css'
import seasonalConsume from '@react-page/seasonalConsume'
import '@react-page/seasonalConsume/lib/index.css'
import settingsModule from '@react-page/settingsModule'
import '@react-page/settingsModule/lib/index.css'
import solarSystemConsumptionProfiles from '@react-page/solarSystemConsumptionProfiles'
import '@react-page/solarSystemConsumptionProfiles/lib/index.css'
import solarSystemDailyCost from '@react-page/solarSystemDailyCost'
import '@react-page/solarSystemDailyCost/lib/index.css'
import solarSystemMonthlyCost from '@react-page/solarSystemMonthlyCost'
import '@react-page/solarSystemMonthlyCost/lib/index.css'
import solarSystemPastMonthCost from '@react-page/solarSystemPastMonthCost'
import '@react-page/solarSystemPastMonthCost/lib/index.css'
import solarSystemStatus from '@react-page/solarSystemStatus'
import '@react-page/solarSystemStatus/lib/index.css'
import solarSystemWarnings from '@react-page/solarSystemWarnings'
import '@react-page/solarSystemWarnings/lib/index.css'
import todaySummary from '@react-page/todaySummary'
import '@react-page/todaySummary/lib/index.css'
import todaySummary_v2 from '@react-page/todaySummary_v2'
import '@react-page/todaySummary_v2/lib/index.css'
import todayWeather from '@react-page/todayWeather'
import '@react-page/todayWeather/lib/index.css'
import totalCost from '@react-page/totalCost'
import '@react-page/totalCost/lib/index.css'

import { Box, Button, Container, Divider, Drawer, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CommentIcon from '@material-ui/icons/Comment'

import { isEqual } from 'lodash'
import moment from 'moment'

import routes, { HOME_PAGE_ROUTE, REPORTS_PAGE_ROUTE } from '../../constants/Routes'
import { AppState } from '../../reducers'

import {
  ClipLoaderSpinner,
  CustomBreadcrumbs,
  DialogCreateVersionAction,
  DialogGenericAction,
  SnackbarWrapper,
} from '../../components'

import { getErrorMessage, getFormattedObjectId, removeUndefinedFielsFromJson } from '../../helpers'

import { createContent, getContent, resetContent, updateContent } from '../../actions'

import { REPORT_DESIGN } from '../../constants/FilesTypes'
import { ManageComments } from './ManageComments'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
  },
  containerForm: {
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  inputFile: {
    display: 'none',
  },
  drawerPaper: {
    width: 300,
  },
  drawerRoot: {
    zIndex: `${10010} !important` as any,
  },
  commentIcon: {
    cursor: 'pointer',
  },
}))

const messages = defineMessages({
  btnSave: {
    id: 'btn-save-report',
    defaultMessage: 'Save report',
  },
  reportNameError: {
    id: 'report-name-error',
    defaultMessage: 'Report name is mandatory',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Edit',
  },
  add: {
    id: 'add',
    defaultMessage: 'Add',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  yes: {
    id: 'yes',
    defaultMessage: 'Yes',
  },
  home: {
    id: 'home',
    defaultMessage: 'Home',
  },
  reports: {
    id: 'reports',
    defaultMessage: 'Reports',
  },
  loadReport: {
    id: 'load-report',
    defaultMessage: 'Load report',
  },
  updateReportSuccessful: {
    id: 'update-report-successful',
    defaultMessage: 'Great, report has been updated',
  },
  createVersion: {
    id: 'create-version',
    defaultMessage: 'Create version',
  },
  createVersionReportSuccessful: {
    id: 'create-version-report-successful',
    defaultMessage: 'Great, version of report has been created',
  },
  titleExitPage: {
    id: 'title-exit-page',
    defaultMessage: 'Are you sure to exit? There are unsaved changes.',
  },
})

export const EditorPage = (props: RouteComponentProps) => {
  const classes = useStyles({})
  const { match } = props

  /**
   * React router Hook
   */
  const params: any = useParams()
  const is_edit_mode = 'name' in params
  const { name: path_name = '' } = params

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    userSession: { basic_info_account, user_id, token },
    currentAccount: { account },
    content: { data, create, update, get },
    intl: { locale },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [is_comments_menu_open, setIsCommentsMenuOpen] = useState<boolean>(false)
  const [current_layout, setCurrentLayout] = useState<Value>({} as Value)
  const [report_name, setReportName] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [is_report_updated, setIsReportUpdated] = useState<boolean>(false)
  const [is_version_created, setIsVersionCreated] = useState<boolean>(false)
  const [there_are_unsaved_changes, setThereAreUnsavedChanged] = useState<boolean>(false)
  const [loadingUploadFile, setLoadingUploadFile] = useState<boolean>(false)

  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'success',
    message: '',
    open: false,
  })
  const [dialog_data, setDialogData] = useState<DialogState>({
    open: false,
    itemName: '',
    itemTitle: '',
    item: null,
  })

  useEffect(() => {
    return () => {
      dispatch(resetContent())
    }
  }, [dispatch])

  /**
   * Get content data
   */
  useEffect(() => {
    if (is_edit_mode && basic_info_account && data === null && !get.loading && !get.error) {
      dispatch(getContent(`${basic_info_account.url}/${REPORT_DESIGN}/${path_name}`))
    }
  }, [basic_info_account, data, dispatch, get, is_edit_mode, path_name])

  /**
   * Set layout data in editor
   */
  useEffect(() => {
    if (data && data.layout) {
      // console.log('Use effect load layout')
      // editor.trigger.editable.update(data.layout)
      setCurrentLayout(data.layout)
    }
  }, [data])

  useEffect(() => {
    if (create.loaded && !create.loading && !create.error) {
      dispatch(push(`${routes[REPORTS_PAGE_ROUTE].path}?lang=${locale}`))
    } else if (update.loaded && !update.loading && !update.error && is_report_updated) {
      setIsReportUpdated(false)
      setAlertInfo({
        open: true,
        type: 'success',
        message: formatMessage(messages.updateReportSuccessful),
      })
    } else if (update.loaded && !update.loading && !update.error && is_version_created) {
      setIsVersionCreated(false)
      setAlertInfo({
        open: true,
        type: 'success',
        message: formatMessage(messages.createVersionReportSuccessful),
      })
    } else if (get.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(get.error),
      })
    } else if (create.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(create.error),
      })
    } else if (update.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(update.error),
      })
    }
  }, [dispatch, create, update, get, is_report_updated, is_version_created, formatMessage, locale])

  /**
   * Component functions
   */

  const handleCloseDialog = () => {
    setDialogData((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const handleCommentMenuToggle = () => {
    setIsCommentsMenuOpen(!is_comments_menu_open)
  }

  const handleClose = () => {
    setAlertInfo((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const actionCreateVersion = (url: string, nameForm: string) => {
    if (is_edit_mode) {
      setIsVersionCreated(true)
      dispatch(
        updateContent(`${url}/${REPORT_DESIGN}/${path_name}`, {
          layout: current_layout,
          versions: {
            op: 'append',
            value: {
              layout: current_layout,
              date: moment(),
              name: nameForm,
              parentTitle: data.title,
              user: user_id,
            },
          },
        })
      )
    }
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setSubmitted(true)
    if (basic_info_account && is_edit_mode) {
      setIsReportUpdated(true)
      setThereAreUnsavedChanged(false)
      dispatch(
        updateContent(`${basic_info_account.url}/${REPORT_DESIGN}/${path_name}`, {
          layout: current_layout,
          versions: {
            op: 'append',
            value: {
              layout: current_layout,
              date: moment(),
              name: `V-${moment().format('x')}`,
              parentTitle: data.title,
              user: user_id,
            },
          },
        })
      )
    } else if (basic_info_account && report_name) {
      setThereAreUnsavedChanged(false)
      const bodyPost = {
        layout: current_layout,
        title: report_name,
        id: getFormattedObjectId(report_name),
        '@type': 'ReportDesign',
      }
      dispatch(createContent(`${basic_info_account.url}/${REPORT_DESIGN}/`, bodyPost))
    }
  }

  /**
   * Render
   */

  const renderSideBarComments = () => {
    return (
      <nav>
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={is_comments_menu_open}
          onClose={handleCommentMenuToggle}
          classes={{
            paper: classes.drawerPaper,
            root: classes.drawerRoot,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <ManageComments report={data} />
        </Drawer>
      </nav>
    )
  }

  const renderCommentBtnAndCreateVersionBtn = () => {
    if (is_edit_mode) {
      return (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setDialogData({
                open: true,
                itemName: data['@name'],
                itemTitle: data.title,
              })
            }}
          >
            {formatMessage(messages.createVersion)}
          </Button>
          <CommentIcon
            className={classes.commentIcon}
            fontSize="large"
            color="primary"
            onClick={handleCommentMenuToggle}
          />
        </>
      )
    }
  }

  const renderInputReportName = () => {
    if (!is_edit_mode) {
      return (
        <TextField
          error={submitted && !report_name}
          helperText={submitted && !report_name ? formatMessage(messages.reportNameError) : ''}
          id="report-name"
          label="Report name"
          value={report_name}
          className={classes.textField}
          onChange={(e: React.ChangeEvent) => {
            const { value } = e.target as HTMLInputElement
            setReportName(value)
          }}
          margin="normal"
        />
      )
    }
  }

  const renderImportBtn = () => {
    if (!is_edit_mode) {
      return (
        <>
          <input
            id="input-upload-file"
            type="file"
            className={classes.inputFile}
            onChange={(e: React.ChangeEvent) => {
              setLoadingUploadFile(true)
              const { files } = e.target as HTMLInputElement
              const reader = new FileReader()
              reader.onload = () => {
                const { result } = reader
                const obj = JSON.parse(result as string)
                // editor.trigger.editable.update(obj)
                setLoadingUploadFile(false)
                setCurrentLayout(obj)
              }
              if (files) {
                reader.readAsText(files[0])
              }
            }}
          />
          <label htmlFor="input-upload-file" id="input-upload-file">
            <Button variant="contained" color="default" className={classes.button} component="span">
              {formatMessage(messages.loadReport)}
              <CloudUploadIcon className={classes.rightIcon} />
            </Button>
          </label>
        </>
      )
    }
  }

  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
      {
        path: routes[REPORTS_PAGE_ROUTE].path,
        message: formatMessage(messages.reports),
      },
    ]
    const pageName = !is_edit_mode ? formatMessage(messages.add) : formatMessage(messages.edit)
    return <CustomBreadcrumbs links={links} currentPageName={pageName} />
  }

  const renderSpinner = () => {
    if (get.loading || loadingUploadFile) {
      return <ClipLoaderSpinner loading={get.loading || loadingUploadFile} size={150} />
    }
  }

  const renderContent = () => {
    if (
      account &&
      !loadingUploadFile &&
      (!is_edit_mode || (!get.error && !get.loading && data !== null))
    ) {
      const matchParams: any = match.params
      const props = {
        token: token,
        selected_account: account['@name'],
      }
      const cellPlugins = [
        accountCustomText(props),
        anualCost(props),
        anualCostParticipation(props),
        energyYearEvolution(props),
        energyYearEvolution_v2(props),
        energyDistributionPeriods(props),
        energyDistributionPeriods_v2(props),
        energyDistributionPeriodsDetail(props),
        invoiceBreakdown(props),
        invoiceBreakdown_v2(props),
        invoiceBreakdownDetail(props),
        highlightActions(props),
        image(props),
        community(props),
        dailyConsume(props),
        dailyConsume_v2(props),
        permanentConsume(props),
        seasonalConsume(props),
        energyCosts(props),
        powerCosts(props),
        actions(props),
        powerMaximeters(props),
        powerOptimization(props),
        powerPenalties(props),
        energyPenalties(props),
        solarSystemStatus(props),
        todaySummary(props),
        todaySummary_v2(props),
        todayWeather(props),
        totalCost(props),
        settingsModule(props),
        solarSystemMonthlyCost(props),
        solarSystemPastMonthCost(props),
        solarSystemDailyCost(props),
        solarSystemConsumptionProfiles(props),
        solarSystemWarnings(props),
        appliancesAdvice(props),
      ]

      return (
        <>
          <Container className={classes.containerForm}>
            <form noValidate onSubmit={handleSubmit} className={classes.form}>
              {renderInputReportName()}
              <Button type="submit" variant="contained" color="primary">
                {formatMessage(messages.btnSave)}
              </Button>
              {renderCommentBtnAndCreateVersionBtn()}
            </form>
            {renderImportBtn()}
          </Container>

          <Divider variant="middle" />

          <Container maxWidth="lg">
            {/* Content area */}
            <Box mt={3} mb={50}>
              <div id={matchParams.name} className="editable-area">
                <div className="editable-area-width">
                  <Editor
                    cellPlugins={cellPlugins}
                    value={current_layout}
                    onChange={(editable: any) => {
                      if (!is_edit_mode) {
                        setThereAreUnsavedChanged(true)
                      } else if (
                        !there_are_unsaved_changes &&
                        !isEqual(current_layout, removeUndefinedFielsFromJson(editable))
                      ) {
                        setThereAreUnsavedChanged(true)
                      }
                      setCurrentLayout(editable)
                    }}
                  />
                </div>
              </div>
            </Box>
          </Container>
        </>
      )
    }
  }

  return (
    <div>
      <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleClose} />

      <Container className={classes.containerForm}>
        {renderBreadcrumbs()}

        <Typography component="h1" variant="h4" align="center">
          {data ? data.title : ''}
        </Typography>
      </Container>
      <DialogCreateVersionAction
        dialogData={dialog_data}
        basicInfoAccount={basic_info_account}
        actionForm={actionCreateVersion}
        handleCloseDialog={handleCloseDialog}
      />
      {renderSpinner()}
      {renderContent()}
      {renderSideBarComments()}

      <NavigationPrompt
        when={there_are_unsaved_changes}
        afterConfirm={() => console.log('After confirm')}
      >
        {({ onConfirm, onCancel }) => (
          <DialogGenericAction
            data={{
              open: true,
              itemName: '',
              itemTitle: '',
            }}
            handleCloseModal={onCancel}
            action={onConfirm}
            messages={{
              title: formatMessage(messages.titleExitPage),
              cancel: formatMessage(messages.cancel),
              actionText: formatMessage(messages.yes),
            }}
          />
        )}
      </NavigationPrompt>
    </div>
  )
}
